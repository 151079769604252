<template>
    <div>
        <small class="ml-2">Unit</small>
        <v-select v-model="unit_id" :options="units" name="unit_id" label="name" :reduce="e => e.id" @input='changeValue()'></v-select>
        <span class="text-danger text-sm" v-show="errors.has('unit_id')">{{errors.first('unit_id')}}</span>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import vSelect from 'vue-select'

export default {
    props: ['value'],
    components:{
        vSelect
    },
    data(){
        return {
            unit_id: this.value
        }
    },
    computed:{
        ...mapState({
            units: state => state.units.all
        })
    },
    watch:{
        async value(val) {
            if (val) {
                this.unit_id = val
            } else {
                this.unit_id = null
            }
            this.changeValue()
        }
    },
    methods:{
        ...mapActions({
            dispatchAll: 'units/all',
        }),
        changeValue(){
            let data = this.units.find(x => x.id == this.unit_id);
            if(data != null) {
                this.$emit('input', data.id);
                this.$emit('on-change', data);
            }
            else {
                this.$emit('input', null);
                this.$emit('on-change', null);
            }
        },
    },
    mounted(){
        this.dispatchAll();
    }
}
</script>