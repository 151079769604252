<template>
    <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <small class="ml-2">{{ titleLabel }}</small>
        <div class="vs-con-input">
            <input
            v-model="search"
            @input="onChange"
            @keydown.down="onArrowDown"
            @keydown.up="onArrowUp"
            @keydown.enter="onEnter"
            type="text"
            class="vs-inputx vs-input--input normal hasValue"
            />
        </div>
        <ul
        v-show="isOpen"
        class="autocomplete-results"
        >
        <li
            v-if="isLoading"
            class="loading"
        >
            Loading results...
        </li>
        <li
            v-else
            v-for="(result, i) in results"
            :key="i"
            @click="setResult(result.label)"
            class="autocomplete-result"
            :class="{ 'is-active': i === arrowCounter }"
        >
            {{ result.label }}
        </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'SearchAutocomplete',
    props: {
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        isAsync: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            type: String,
            required: false,
            default: ''
        },
        title: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            search: '',
            results: [],
            isOpen: false,
            isLoading: false,
            arrowCounter: -1,
            titleLabel: this.title
        };
    },
    watch: {
        // items: function (value) {
            // if (this.isAsync) {
            //     this.results = value;
            //     this.isOpen = true;
            //     this.isLoading = true;
            // } else if (this.items) {
            //     this.isOpen = false;
            //     this.isLoading = false;
            //     this.isAsync = false;
            // }
        // }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        if(this.value){
            this.search = this.value;
        }
    },
    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        setResult(result) {
            this.search = result;
            this.isOpen = false;
            this.$emit('input', this.search);
        },
        filterResults() {
            this.results = this.items.filter(item => item.label.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
            // console.log('result', this.results)
        },
        onChange() {
            this.$emit('input', this.search);
            this.filterResults();
            this.isOpen = true;
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.arrowCounter = -1;
                this.isOpen = false;
            }
        },
        onArrowDown() {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1;
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1;
            }
        },
        onEnter() {
            this.search = this.results[this.arrowCounter].label;
            this.arrowCounter = -1;
            this.isOpen = false;
        }
    },
}
</script>

<style>
  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    min-height: 1em;
    max-height: 6em;
    overflow: auto;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;
  }
</style>