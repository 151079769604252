<template>
  <div class="vx-row">
    <div
      class="vx-col sm:w-full md:w-1/2 mb-base"
      v-if="!$route.params.job_order_id"
    >
      <vx-card title="1. Order Information">
        <!-- FORM START -->
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <browse-company
              class="w-full"
              v-validate="'required'"
              name="company_id"
              label="Company"
              v-model="company_id"
            ></browse-company>
            <span
              class="text-danger text-sm"
              v-show="errors.has('company_id')"
              >{{ errors.first("company_id") }}</span
            >
          </div>
        </div>
        <vs-divider>Customer</vs-divider>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <browse-contact
              class="w-full"
              :iscustomer="true"
              v-validate="'required'"
              name="customer_id"
              label="Customer"
              v-model="customer_id"
            ></browse-contact>
            <span
              class="text-danger text-sm"
              v-show="errors.has('customer_id')"
              >{{ errors.first("customer_id") }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <browse-contact-address
              class="w-full"
              :contact-id="customer_id"
              v-validate="'required'"
              name="sender_id"
              label="Shipper"
              v-model="sender_id"
            ></browse-contact-address>
            <span
              class="text-danger text-sm"
              v-show="errors.has('sender_id')"
              >{{ errors.first("sender_id") }}</span
            >
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <browse-contact-address
              class="w-full"
              :contact-id="customer_id"
              v-validate="'required'"
              name="receiver_id"
              label="Consignee"
              v-model="receiver_id"
            ></browse-contact-address>
            <span
              class="text-danger text-sm"
              v-show="errors.has('receiver_id')"
              >{{ errors.first("receiver_id") }}</span
            >
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <small class="ml-1">Order Date</small>
            <flat-pickr
              :config="flatPickrConfig"
              class="w-full"
              v-model="shipment_date"
            ></flat-pickr>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <small class="ml-1">Email Received</small>
            <flat-pickr
              :config="flatPickrConfigTime"
              class="w-full"
              v-model="email_received"
            ></flat-pickr>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <small class="ml-1">SIPB Received</small>
            <flat-pickr
              :config="flatPickrConfigTime"
              class="w-full"
              v-model="sipb_received"
            ></flat-pickr>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              name="no_shipment"
              label="No. Reference"
              v-model="no_shipment"
              @input="changeNoReference()"
            ></vs-input>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <browse-route
              class="w-full"
              v-validate="'required'"
              name="route_id"
              label="Route"
              @input="routeOnChange()"
              v-model="route_id"
            ></browse-route>
            <span class="text-danger text-sm" v-show="errors.has('route_id')">{{
              errors.first("route_id")
            }}</span>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <browse-select-vehicle-type
              class="w-full"
              :value="vehicle_type_id"
              v-model="vehicle_type_id"
            ></browse-select-vehicle-type>
            <!-- <browse-vehicle-type class="w-full" v-validate="'required'" name="vehicle_type_id" label="Vehicle Type" v-model="vehicle_type_id"></browse-vehicle-type> -->
            <!-- <span class="text-danger text-sm" v-show="errors.has('vehicle_type_id')">{{errors.first('vehicle_type_id')}}</span> -->
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              name="description"
              label="Order Description"
              v-model="description"
            ></vs-input>
          </div>
        </div>
        <!-- FORM END -->
      </vx-card>
    </div>
    <div
      :class="
        $route.params.job_order_id
          ? 'vx-col sm:w-full mb-base md:w-full'
          : 'vx-col sm:w-full mb-base md:w-1/2'
      "
    >
      <vx-card title="">
        <!-- DETAIL START -->
        <vs-tabs v-model="activeTab">
          <vs-tab label="Items">
            <div>
              <div class="vx-row mb-3 mt-2">
                <div class="vx-col w-full">
                  <small>Source</small>
                  <div class="mt-1">
                    <input
                      class="mr-2"
                      type="radio"
                      name="source"
                      v-model="fd.source"
                      value="price_list"
                      vs-value="price_list"
                    />
                    <span class="mr-6">Price List</span>

                    <input
                      class="mr-2"
                      type="radio"
                      v-model="fd.source"
                      value="contract"
                      vs-value="contract"
                      name="source"
                    />
                    <span class="mr-2">Contract</span>
                  </div>
                </div>
              </div>
              <div class="vx-row mb-3" v-if="fd.source == 'contract'">
                <div class="vx-col w-full">
                  <browse-price-list
                    :params="{ route_id: route_id }"
                    :customer_id="customer_id"
                    class="w-full"
                    @on-change="onchangePL"
                    label="Commodity Price"
                    v-model="fd.price_list_id"
                    :value="fd.price_list_id"
                  ></browse-price-list>
                </div>
              </div>
              <div class="vx-row mb-3" v-if="fd.source == 'price_list'">
                <div class="vx-col w-full">
                  <browse-price-list
                    :params="{ route_id: route_id }"
                    class="w-full"
                    @on-change="onchangePL"
                    label="Commodity Price"
                    v-model="fd.price_list_id"
                    :value="fd.price_list_id"
                  ></browse-price-list>
                </div>
              </div>
              <div class="vx-row mb-3">
                <div class="vx-col w-full">
                  <browse-commodity
                    disabled
                    @on-change="changeCommodity"
                    class="w-full"
                    label="Commodity"
                    v-model="fd.commodity_id"
                    :value="fd.commodity_id"
                  ></browse-commodity>
                </div>
              </div>
              <div class="vx-row mb-3">
                <div class="vx-col w-full mb-2">
                  <small>Price Imposition</small>
                </div>
                <div class="vx-col w-full flex">
                  <input
                    @input="itemOnChange()"
                    type="radio"
                    name="imposition"
                    v-model="fd.imposition"
                    vs-value="1"
                    value="1"
                  /><span class="ml-2">Tonnase</span>
                  <input
                    @input="itemOnChange()"
                    type="radio"
                    name="imposition"
                    class="ml-2"
                    v-model="fd.imposition"
                    vs-value="2"
                    value="2"
                  /><span class="ml-2">Volume</span>
                  <input
                    @input="itemOnChange()"
                    type="radio"
                    name="imposition"
                    class="ml-2"
                    v-model="fd.imposition"
                    vs-value="3"
                    value="3"
                  /><span class="ml-2">Item</span>
                  <input
                    @input="itemOnChange()"
                    type="radio"
                    name="imposition"
                    class="ml-2"
                    v-model="fd.imposition"
                    vs-value="4"
                    value="4"
                  /><span class="ml-2">Full</span>
                </div>
              </div>
              <div class="vx-row mb-3">
                <div class="vx-col w-1/3">
                  <vs-input
                    @input="itemOnChange()"
                    type="number"
                    class="w-full"
                    label="Qty"
                    v-model="fd.qty"
                  ></vs-input>
                </div>
                <div class="vx-col w-2/3">
                  <browse-select-unit
                    class="w-full"
                    :value="fd.unit_id"
                    v-model="fd.unit_id"
                    @on-change="changeUnit"
                  ></browse-select-unit>
                  <!-- <browse-unit @on-change="changeUnit" class="w-full" label="Unit" v-model="fd.unit_id"></browse-unit> -->
                </div>
              </div>
              <div class="vx-row mb-3">
                <div class="vx-col w-1/4">
                  <vs-input
                    @input="itemOnChange()"
                    type="number"
                    class="w-full"
                    label="Height (m)"
                    v-model="fd.height"
                  ></vs-input>
                </div>
                <div class="vx-col w-1/4">
                  <vs-input
                    @input="itemOnChange()"
                    type="number"
                    class="w-full"
                    label="Length (m)"
                    v-model="fd.length"
                  ></vs-input>
                </div>
                <div class="vx-col w-1/4">
                  <vs-input
                    @input="itemOnChange()"
                    type="number"
                    class="w-full"
                    label="Wide (m)"
                    v-model="fd.wide"
                  ></vs-input>
                </div>
                <div class="vx-col w-1/4">
                  <vs-input
                    @input="itemOnChange()"
                    type="number"
                    readonly
                    class="w-full"
                    label="Volume (m3)"
                    v-model="fd.volume"
                  ></vs-input>
                </div>
              </div>
              <div class="vx-row mb-3">
                <div class="vx-col w-full">
                  <vs-input
                    @input="itemOnChange()"
                    type="number"
                    class="w-full"
                    label="Weight (Kg)"
                    v-model="fd.weight"
                  ></vs-input>
                </div>
              </div>
              <div class="vx-row mb-3">
                <div class="vx-col w-full">
                  <vs-input
                    type="number"
                    readonly
                    class="w-full"
                    label="Price"
                    v-model="fd.price"
                  ></vs-input>
                </div>
              </div>
              <div class="vx-row mb-3">
                <div class="vx-col w-full">
                  <vs-input
                    type="number"
                    readonly
                    class="w-full"
                    label="Total Price"
                    v-model="fd.total_price"
                  ></vs-input>
                </div>
              </div>
              <div class="vx-row mb-3">
                <div class="vx-col w-full">
                  <vs-input
                    type="text"
                    class="w-full"
                    label="QRcode / Barcode"
                    v-model="fd.qrcode"
                    :readonly="
                      is_readonly_barcode && fd.commodity_type == 'car'
                    "
                  ></vs-input>
                </div>
              </div>
              <div class="vx-row mb-3">
                <div class="vx-col w-full">
                  <vs-input
                    type="text"
                    class="w-full"
                    label="Description"
                    v-model="fd.description"
                  ></vs-input>
                </div>
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Additional" :disabled="disabled_additional">
            <div v-if="fd.commodity_type === 'car'">
              <div class="vx-row mb-3">
                <div class="vx-col w-full">
                  <vs-input
                    class="w-full"
                    label="No. Faktur"
                    v-model="fd.no_faktur"
                  ></vs-input>
                </div>
              </div>

              <div class="vx-row mb-3">
                <div class="vx-col w-full">
                  <small class="ml-1">Faktur Date</small>
                  <flat-pickr
                    :config="flatPickrConfig"
                    class="w-full"
                    v-model="fd.faktur_date"
                  ></flat-pickr>
                </div>
              </div>

              <div class="vx-row mb-3">
                <div class="vx-col w-full">
                  <browse-car-factory
                    class="w-full"
                    v-model="fd.car_factory_id"
                    :value="fd.car_factory_id"
                    @on-change="changeCarFactory"
                  ></browse-car-factory>
                </div>
              </div>

              <div class="vx-row mb-3">
                <div class="vx-col w-full">
                  <browse-car-type
                    class="w-full"
                    v-model="fd.car_type_id"
                    :value="fd.car_type_id"
                    @on-change="changeCarType"
                  ></browse-car-type>
                </div>
              </div>

              <div class="vx-row mb-3">
                <div class="vx-col w-full">
                  <vs-input
                    class="w-full"
                    label="No. Chasis"
                    v-model="fd.no_chasis"
                    @input="chassisChanged()"
                  ></vs-input>
                </div>
              </div>
              <div class="vx-row mb-3">
                <div class="vx-col w-full">
                  <vs-input
                    class="w-full"
                    label="No. Machine"
                    v-model="fd.no_machine"
                  ></vs-input>
                </div>
              </div>

              <div class="vx-row mb-3">
                <div class="vx-col w-full">
                  <browse-car-color
                    class="w-full"
                    v-model="fd.car_color_id"
                    @on-change="changeCarColor"
                  ></browse-car-color>
                </div>
              </div>

              <div class="vx-row mb-3">
                <div class="vx-col w-full">
                  <SearchAutocomplete
                    :items="interior_opt"
                    :title="'Interior'"
                    v-model="fd.interior"
                  />
                </div>
              </div>
            </div>
            <div v-if="fd.commodity_type === 'sparepart'">
              <div class="vx-row mb-3">
                <div class="vx-col w-full">
                  <browse-car-sparepart
                    class="w-full"
                    v-model="fd.car_sparepart_id"
                    :value="fd.car_sparepart_id"
                    @on-change="changeCarSparepart"
                  ></browse-car-sparepart>
                </div>
              </div>
            </div>
          </vs-tab>
          <div>
            <div class="vx-col w-full ml-3">
              <div
                class="flex"
                v-if="!$route.params.job_order_id && !this.fd.id"
              >
                <vs-button
                  icon="icon-plus"
                  icon-pack="feather"
                  size="small"
                  @click="append"
                  >Add to Goods List</vs-button
                >
                <vs-button
                  class="ml-2"
                  size="small"
                  color="warning"
                  @click="resetFd"
                  >Reset Goods</vs-button
                >
              </div>

              <div
                class="flex"
                v-if="!$route.params.job_order_id && this.fd.id"
              >
                <vs-button
                  icon="icon-plus"
                  icon-pack="feather"
                  size="small"
                  @click="append"
                  >Save</vs-button
                >
                <vs-button
                  class="ml-2"
                  size="small"
                  color="warning"
                  @click="resetEditFd()"
                  >Cancel Edit</vs-button
                >
              </div>

              <div class="flex" v-if="$route.params.job_order_id">
                <vs-button
                  icon="icon-plus"
                  icon-pack="feather"
                  size="small"
                  @click="storeDetails"
                  >Save</vs-button
                >
                <vs-button
                  class="ml-2"
                  size="small"
                  color="warning"
                  @click="$router.back()"
                  >Back</vs-button
                >
              </div>
            </div>
          </div>
        </vs-tabs>
        <!-- DETAIL END -->
      </vx-card>
    </div>
    <div class="vx-col w-full" v-if="!$route.params.job_order_id">
      <vx-card title="">
        <vs-tabs>
          <vs-tab label="Item">
            <vs-table stripe :data="details" :sync="details" class="mb-3">
              <template slot="thead">
                <vs-th sort-key="commodity_name">Commodity</vs-th>
                <vs-th sort-key="qty">Qty</vs-th>
                <vs-th sort-key="unit_name">Unit</vs-th>
                <vs-th sort-key="volume">Volume (m<sup>3</sup>)</vs-th>
                <vs-th sort-key="weight">Weight (Kg)</vs-th>
                <vs-th sort-key="price">Price</vs-th>
                <vs-th sort-key="total_price">Total Price</vs-th>
                <vs-th sort-key="qrcode">Qrcode / Barcode</vs-th>
                <vs-th sort-key="description">Description</vs-th>
                <vs-th></vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="index" v-for="(tr, index) in data">
                  <vs-td :data="tr.commodity_name">{{
                    tr.commodity_name
                  }}</vs-td>
                  <vs-td label="Qty" class="text-right" :data="tr.qty">{{
                    tr.qty
                  }}</vs-td>
                  <vs-td label="Unit" :data="tr.unit_name">{{
                    tr.unit_name
                  }}</vs-td>
                  <vs-td
                    label="Volume (m<sup>3</sup>)"
                    class="text-right"
                    :data="tr.volume"
                    >{{ tr.volume }}</vs-td
                  >
                  <vs-td
                    label="Weight (Kg)"
                    class="text-right"
                    :data="tr.weight"
                    >{{ tr.weight }}</vs-td
                  >
                  <vs-td label="Price" class="text-right" :data="tr.price">{{
                    tr.price | numFormat
                  }}</vs-td>
                  <vs-td
                    label="Total Price"
                    class="text-right"
                    :data="tr.total_price"
                    >{{ tr.total_price | numFormat }}</vs-td
                  >
                  <vs-td label="Qrcode / Barcode" :data="tr.qrcode">{{
                    tr.qrcode
                  }}</vs-td>
                  <vs-td label="Description" :data="tr.description">{{
                    tr.description
                  }}</vs-td>
                  <vs-td>
                    <!-- <vs-button size="small" type="line" @click="editTable(tr.id)" icon-pack="feather" icon="icon-edit"></vs-button> -->
                    <vs-button
                      size="small"
                      color="danger"
                      type="line"
                      @click="deleteTable(index)"
                      icon-pack="feather"
                      icon="icon-trash"
                    ></vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vs-tab>

          <vs-tab label="Additional">
            <vs-table :data="details" class="mb-3">
              <template slot="thead">
                <vs-th sort-key="commodity_name">Goods / Commodity</vs-th>
                <vs-th>Info</vs-th>
                <vs-th></vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr
                  :key="index"
                  v-for="(tr, index) in data.filter((item) => {
                    return (
                      item.commodity_type === 'car' ||
                      item.commodity_type === 'sparepart'
                    );
                  })"
                >
                  <vs-td :data="tr.commodity_name">{{
                    tr.commodity_name
                  }}</vs-td>
                  <vs-td label="Info">
                    <div v-if="tr.commodity_type == 'car'">
                      <span><strong>No Faktur:</strong></span> {{ tr.no_faktur
                      }}<br />
                      <hr />
                      <span><strong>Faktur Date:</strong></span>
                      {{ tr.faktur_date }}<br />
                      <hr />
                      <span><strong>Car Factory:</strong></span>
                      {{ tr.car_factory_name }}<br />
                      <hr />
                      <span><strong>Car Type:</strong></span>
                      {{ tr.car_type_name }}<br />
                      <hr />
                      <span><strong>No Chasis:</strong></span> {{ tr.no_chasis
                      }}<br />
                      <hr />
                      <span><strong>No Machine:</strong></span>
                      {{ tr.no_machine }}<br />
                      <hr />
                      <span><strong>Exterior:</strong></span>
                      {{ tr.car_color_name }}<br />
                      <hr />
                      <span><strong>Interior:</strong></span> {{ tr.interior }}
                    </div>
                    <div v-if="tr.commodity_type == 'sparepart'">
                      <span><strong>Car Sparepart:</strong></span>
                      {{ tr.car_sparepart_name }}
                    </div>
                  </vs-td>
                  <vs-th>
                    <vs-button
                      size="small"
                      color="danger"
                      type="line"
                      @click="deleteTable(index)"
                      icon-pack="feather"
                      icon="icon-trash"
                    ></vs-button>
                  </vs-th>
                </vs-tr>
              </template>
            </vs-table>
          </vs-tab>
        </vs-tabs>
        <div class="flex">
          <vs-button
            @click="store(false)"
            color="primary"
            icon-pack="feather"
            icon="icon-save"
            >Save</vs-button
          >
          <vs-button
            class="ml-2 mr-2"
            @click="store(true)"
            color="primary"
            icon-pack="feather"
            icon="icon-save"
            >Save with Packing List</vs-button
          >
          <slot name="footer"></slot>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import BrowseCarFactory from "@browse/CarFactory.vue";
import BrowseCarType from "@browse/CarType.vue";
import BrowseCarColor from "@browse/CarColor.vue";
import BrowseCompany from "@browse/Company.vue";
import BrowseContact from "@browse/Contact.vue";
import BrowseRoute from "@browse/Route.vue";
import BrowseContactAddress from "@browse/ContactAddress.vue";
// import BrowseVehicleType from '@browse/VehicleType.vue'
import BrowseCommodity from "@browse/Commodity.vue";
// import BrowseUnit from '@browse/Unit.vue'
import BrowsePriceList from "@browse/PriceList.vue";
import BrowseCarSparepart from "@browse/CarSparepart.vue";
import BrowseSelectVehicleType from "@browse_select/VehicleType.vue";
import BrowseSelectUnit from "@browse_select/Unit.vue";
import SearchAutocomplete from "@browse/SearchAutoComplete.vue"

let formDetail = {
  source: "price_list",
  price_list_id: null,
  commodity_id: null,
  commodity_name: null,
  unit_id: null,
  unit_name: null,
  qty: 0,
  weight: 0,
  height: 0,
  length: 0,
  wide: 0,
  volume: 0,
  price: 0,
  total_price: 0,
  imposition: 3,
  description: null,
  no_chasis: null,
  no_machine: null,
  no_faktur: null,
  faktur_date: null,
  interior: '',
  car_factory_id: null,
  car_type_id: null,
  car_color_id: null,
  car_sparepart_id: null,
  qrcode: null,
  commodity_type: null,
  flatPickrConfig: {
    altFormat: "j M Y",
    altInput: true,
    dateFormat: "Y-m-d",
  },
};
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  components: {
    BrowseCompany,
    BrowseContact,
    BrowseContactAddress,
    BrowseRoute,
    // BrowseVehicleType,
    BrowseSelectVehicleType,
    BrowseCommodity,
    // BrowseUnit,
    BrowseSelectUnit,
    BrowsePriceList,
    BrowseCarFactory,
    BrowseCarType,
    BrowseCarColor,
    BrowseCarSparepart,
    flatPickr,
    SearchAutocomplete
  },
  data() {
    return {
      is_readonly_barcode: false,
      activeTab: 0,
      disabled_additional: true,
      fd_tmp: null,
      company_id: null,
      customer_id: null,
      route_id: null,
      sender_id: null,
      receiver_id: null,
      vehicle_type_id: null,
      container_type_id: null,
      description: null,
      no_shipment: "",
      shipment_date: new Date(),
      email_received: new Date(),
      sipb_received: new Date(),
      fd: {
        source: "price_list",
        price_list_id: null,
        commodity_id: null,
        commodity_name: null,
        unit_id: null,
        unit_name: null,
        qty: 0,
        weight: 0,
        height: 0,
        length: 0,
        wide: 0,
        volume: 0,
        price: 0,
        total_price: 0,
        imposition: 3,
        description: null,
        no_chasis: null,
        no_machine: null,
        no_faktur: null,
        faktur_date: null,
        interior: '',
        car_factory_id: null,
        car_type_id: null,
        car_color_id: null,
        car_sparepart_id: null,
        qrcode: this.randomQrcode(10),
        commodity_type: null,
      },
      details: [],
      interior_opt: [],
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      flatPickrConfigTime: {
        altFormat: "j M Y - H:i",
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: "Y-m-d H:i",
      },
      chosen_pl: {
        price_tonase: 0,
        price_volume: 0,
        price_item: 0,
        price_full: 0,
      },
    };
  },
  watch: {
    customer_id() {
      this.sender_id = null;
      this.receiver_id = null;
    },
  },
  methods: {
    ...mapActions({
      dispatchStoreDetails: "job_orders/store_details",
      dispatchUpdateDetails: "job_orders/update_details",
      dispatchShowDetails: "job_orders/show_details",
      dispatchStore: "job_orders/store",
      dispatchUpdate: "job_orders/update",
      dispatchShow: "job_orders/show",
      dispatchApplication: "application/show",
      dispatchInteriors: "interiors/all",
    }),
    randomQrcode(length) {
      let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let result = "";
      for (let i = length; i > 0; --i)
        result += chars[Math.round(Math.random() * (chars.length - 1))];
      return result;
    },
    append() {
      if (this.fd.total_price == 0)
        return this.$vs.notify({
          title: `Oops!`,
          text: `Goods total price is 0`,
          color: `warning`,
        });

      let qrcodeCheck = this.details.filter((qrcode) => {
        return qrcode.qrcode === this.fd.qrcode;
      });

      if (qrcodeCheck.length > 0)
        return this.$vs.notify({
          title: `Oops!`,
          text: `Qrcode/Barcode is exist`,
          color: `warning`,
        });

      setTimeout(() => {
        if (this.is_readonly_barcode && this.fd.commodity_type == "car") {
          this.fd.qrcode = this.fd.no_chasis;
        }
        this.details.push(this.fd);
        // console.log(this.fd, this.details, 'details')
        this.resetFd();
      }, 100);
    },
    routeOnChange() {
      this.fd.price_list_id = null;
      this.onchangePL({
        name: "",
      });
      this.itemOnChange();
    },
    itemOnChange() {
      setTimeout(() => {
        let v = this.fd;
        let qty = parseFloat(v.qty);
        let height = parseFloat(v.height);
        let length = parseFloat(v.length);
        let wide = parseFloat(v.wide);
        let volume = parseFloat(v.volume);
        let weight = parseFloat(v.weight);
        let price = parseFloat(v.price);

        this.fd.volume = (height * length * wide) / 1000000;
        if (v.imposition == 1) {
          price = parseFloat(this.chosen_pl.price_tonase);
          this.fd.price = price;
          this.fd.total_price = weight * price;
        } else if (v.imposition == 2) {
          price = parseFloat(this.chosen_pl.price_volume);
          this.fd.price = price;
          this.fd.total_price = volume * price;
        } else if (v.imposition == 3) {
          price = parseFloat(this.chosen_pl.price_item);
          this.fd.price = price;
          this.fd.total_price = qty * price;
        } else {
          price = parseFloat(this.chosen_pl.price_full);
          this.fd.price = price;
          this.fd.total_price = price;
        }
      }, 300);
    },
    resetFd() {
      this.fd = Object.assign({}, formDetail);
      this.fd.qrcode = this.randomQrcode(10);
      this.activeTab = 0;
      this.disabled_additional = true;
    },
    onchangePL(data) {
      this.chosen_pl = data;
      this.fd.commodity_id = data.commodity_id;
      this.fd.commodity_type = data.commodity_type;
      this.fd.no_faktur = null;
      if (data.commodity_type != null && data.commodity_type != "general") {
        this.disabled_additional = false;
        this.fd.no_faktur = this.no_shipment;
      }
    },
    changeCommodity(data) {
      this.fd.commodity_name = data.name;
      this.fd.commodity_type = data.type;
      if (this.fd.commodity_type == "car" && this.is_readonly_barcode) {
        this.fd.qrcode = "";
      }
    },
    changeCarFactory(data) {
      this.fd.car_factory_name = data.name;
    },
    changeCarType(data) {
      this.fd.car_type_name = data.name;
    },
    changeCarColor(data) {
      this.fd.car_color_name = data.name;
    },
    changeUnit(data) {
      if (data != null) {
        this.fd.unit_name = data.name;
      } else {
        this.fd.unit_name = null;
      }
    },
    changeCarSparepart(data) {
      this.fd.car_sparepart_name = data.name;
    },
    changeNoReference() {
      // console.log(this.no_shipment);
      if (this.disabled_additional == false) {
        if (this.fd.commodity_type == "car") {
          this.fd.no_faktur = this.no_shipment;
        }
      }
    },
    editTable(id) {
      let fd = this.details.find((x) => x.id == id);
      this.fd = fd;
      this.fd_tmp.id = fd.id;
      this.fd_tmp.qty = fd.qty;
      this.fd_tmp.source = fd.source;
      this.fd_tmp.price_list_id = fd.price_list_id;
      this.fd_tmp.commodity_id = fd.commodity_id;
      this.fd_tmp.commodity_name = fd.commodity_name;
      this.fd_tmp.unit_id = fd.unit_id;
      this.fd_tmp.unit_name = fd.unit_name;
      this.fd_tmp.qty = fd.qty;
      this.fd_tmp.weight = fd.weight;
      this.fd_tmp.height = fd.height;
      this.fd_tmp.length = fd.length;
      this.fd_tmp.wide = fd.wide;
      this.fd_tmp.volume = fd.volume;
      this.fd_tmp.price = fd.price;
      this.fd_tmp.total_price = fd.total_price;
      this.fd_tmp.imposition = fd.imposition;
      this.fd_tmp.description = fd.description;
      this.fd_tmp.no_chasis = fd.no_chasis;
      this.fd_tmp.no_machine = fd.no_machine;
      this.fd_tmp.no_faktur = fd.no_faktur;
      this.fd_tmp.faktur_date = fd.faktur_date;
      this.fd_tmp.interior = fd.interior;
      this.fd_tmp.car_factory_id = fd.car_factory_id;
      this.fd_tmp.car_type_id = fd.car_type_id;
      this.fd_tmp.car_color_id = fd.car_color_id;
      this.fd_tmp.car_sparepart_id = fd.car_sparepart_id;
      this.fd_tmp.qrcode = fd.qrcode;
      this.fd_tmp.commodity_type = fd.commodity_type;
    },
    deleteTable(i) {
      this.details.splice(i, 1);
    },
    store(isPl = false) {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        let payload = {
          id: this.id,
          company_id: this.company_id,
          customer_id: this.customer_id,
          route_id: this.route_id,
          sender_id: this.sender_id,
          receiver_id: this.receiver_id,
          vehicle_type_id: this.vehicle_type_id,
          container_type_id: this.container_type_id,
          no_shipment: this.no_shipment,
          shipment_date: this.shipment_date,
          email_received: this.email_received,
          sipb_received: this.sipb_received,
          details: this.details,
          saveAsManifest: isPl,
        };
        try {
          if (this.id) {
            let { data } = await this.dispatchUpdate(payload);
            this.$emit("on-success", data);
          } else {
            // console.log(payload)
            let { data } = await this.dispatchStore(payload);
            this.$emit("on-success", data);
          }
          this.$vs.notify({
            title: `OK`,
            text: `Data saved successfully`,
            color: `success`,
          });
        } catch (error) {
          this.$vs.notify({
            title: "Oops!",
            text: error.data.message,
            color: "danger",
          });
        }
      });
    },

    storeDetails() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        let payload = this.fd;
        payload.job_order_id = this.$route.params.job_order_id;
        payload.id = this.$route.params.id;
        try {
          if (this.$route.params.id) {
            let { data } = await this.dispatchUpdateDetails(payload);
            this.$emit("on-success", data);
          } else {
            let { data } = await this.dispatchStoreDetails(payload);
            this.$emit("on-success", data);
          }
          this.$vs.notify({
            title: `OK`,
            text: `Data saved successfully`,
            color: `success`,
          });
        } catch (error) {
          if (error.status == 422) {
            for (let x in error.data) {
              this.$vs.notify({
                title: "Oops!",
                text: error.data[x][0],
                color: "danger",
              });
              break;
            }
          } else {
            this.$vs.notify({
              title: "Oops!",
              text: error.data.message,
              color: "danger",
            });
          }
        }
      });
    },
    async getDetail() {
      let { data } = await this.dispatchShow(this.id);
      this.company_id = data.company_id;
      this.customer_id = data.customer_id;
      this.route_id = data.route_id;
      this.sender_id = data.sender_id;
      this.receiver_id = data.receiver_id;
      this.vehicle_type_id = data.vehicle_type_id;
      this.container_type_id = data.container_type_id;
      this.no_shipment = data.no_shipment;
      this.shipment_date = data.shipment_date;
      this.email_received = data.email_received;
      this.sipb_received = data.sipb_received;
    },
    chassisChanged() {
      setTimeout(() => {
        if (this.is_readonly_barcode && this.fd.commodity_type == "car") {
          this.fd.qrcode = this.fd.no_chasis;
        }
      }, 600);
    },
    async getSetting() {
      let { data } = await this.dispatchApplication("job_order");
      // console.log(data);
      if (data.data.barcode_type) {
        if (data.data.barcode_type == "no_chassis") {
          this.is_readonly_barcode = true;
        }
      }
    },
    async getInteriors() {
      let { data } = await this.dispatchInteriors();
      if(data){
        data = data.map(function(i){
          return {
            'value': i.id,
            'label': i.name
          }
        });

        this.interior_opt = data
      }
      // console.log("mapped",this.interior_opt);
    },
    async showDetail() {
      let payload = {
        job_order_id: this.$route.params.job_order_id,
        id: this.$route.params.id,
      };
      if (this.$route.params.job_order_id && this.$route.params.id) {
        try {
          this.fd.price = 10000;
          let { data } = await this.dispatchShowDetails(payload);
          this.fd = data;
          if (this.fd.qrcode == null) {
            this.fd.qrcode = this.randomQrcode(10);
          }
        } catch (e) {
          // console.log(e);
        }
      }
    },
  },
  mounted() {
    this.getSetting();
    this.getInteriors();
    if (!this.$route.params.job_order_id) {
      if (this.id) {
        this.getDetail();
      }
    } else {
      this.id = this.$route.params.job_order_id;
      this.getDetail();
    }
  },
  async created() {
    await this.showDetail();
  },
};
</script>
<style>
</style>