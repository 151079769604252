<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <form-create :id="$route.params.id" @on-success="$router.back()">
        <template v-slot:footer>
          <vs-button :to="{name:'job_orders'}" type="border" color="warning">Back</vs-button>
        </template>
      </form-create>
    </div>
  </div>
</template>

<script>
import FormCreate from './sections/Form.vue'
export default {
  components:{
    FormCreate
  }
}
</script>

<style>

</style>